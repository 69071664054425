import * as types from '../actionTypes';
import { handleExtCatesData, getProductIds, getFirstOfProductsIds, showProductsListStyle, changeNumShow } from '../../utils';
import { LOCAL_STORAGE } from '../../utils/localStorage';

const defaultState = {
  isLogin: false,
  isSku: false,
  wordDesc: '商品',
  searchLoading: true,
  // searchParamsBody: {},
  allData: {},
  categorys: [],
  brands: [],
  extCates: [],
  advancedCates: [],
  mulFilter: false, // 是否是多选模式
  mulBtnIdx: -1, // 多选模式开启的index
  moreBtnIdxs: [false, false, false, false, false],
  recommends: [],
  products: [],
  allProductsIds: "",
  firstOfProductsIds: "",
  productsListStyle: '',
  advertisements: [],
  productsPriceInfo: {},
  shopChat: [],
  comments: [],
  compareList: JSON.parse(LOCAL_STORAGE.getItem('compareList') || '[]'),
  modalShow: JSON.parse(LOCAL_STORAGE.getItem('compareList') || '[]').length === 0 ? false : JSON.parse(LOCAL_STORAGE.getItem('comapreModalShow') || 'false'),
  tipShow: false,
  tips: '',
  crumb: [],
  itemTagInfo: {},
  productsFollowStatus: {},
  oldSearchParamsBody: {},
  totalItem: undefined,
  isPlus95: false,
  urlParams: {}
}


const searchReducer = (state = defaultState, { type, value }) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case types.SEARCH_PRODUCTS:
      const { 
        // params,
        data, 
      } = value;
      // newState.searchParamsBody = JSON.parse(params.body);
      newState.allData = data;
      const { brands = [], extCates = [], items = [], itemTagInfo = {}, categorys = [], isSku = false, crumbs = [], totalItem = 0, isPlus95 = false } = data;
      if (isSku) {
        newState.isSku = isSku;
      } else {
        const sliceIndex = brands?.length > 0 ? 3 : 4;
        const filter = extCates?.length > 3 ? extCates?.slice(0, sliceIndex) : extCates;
        const advancedCates = extCates?.length > 3 ? extCates?.slice(sliceIndex, 12) : [];
        extCates?.slice(12).length && advancedCates?.push({
          id: -1,
          name: "其他分类",
          extAttrs: handleExtCatesData(extCates.slice(12))
        })
        newState.categorys = categorys;
        newState.brands = brands || [];
        newState.extCates = filter || [];
        newState.advancedCates = advancedCates || [];
        newState.products = items || [];
        newState.itemTagInfo = itemTagInfo || {};
        newState.allProductsIds = items?.length ? getProductIds(items).toString() : '';
        newState.firstOfProductsIds = items?.length ? getFirstOfProductsIds(items).toString() : '';
        newState.advertisements = [];
        newState.productsListStyle = items?.length ? `item-style-${showProductsListStyle(items)}` : '';
        newState.crumb = crumbs;
        newState.totalItem = totalItem;
        newState.isPlus95 = isPlus95;
        // if (!!crumb) {
        //   newState.crumb = [...newState.crumb, crumb];
        // }
      }
      break;
    case types.CHANGE_CRUMB:
      newState.crumb = value;
      break;
    case types.URL_PARAMS:
      newState.urlParams = value;
      break;
    case types.MULFILTER:
      newState.mulFilter = value;
      break;
    case types.MULBTNIDX:
      newState.mulBtnIdx = value;
      break;
    case types.MOREBTNIDXS:
      newState.moreBtnIdxs[value] = !newState.moreBtnIdxs[value]
      break;
    case types.RECOMMENDS:
      newState.recommends = value || [];
      break;
    case types.ADVERTISEMENTS:
      newState.advertisements = [...newState.advertisements, ...value];
      break;
    case types.PRODUCTSPRICE:
      newState.productsPriceInfo = {...newState.productsPriceInfo, ...value};
      break;
    case types.SHOPCHAT:
      newState.shopChat = value;
      break;
    case types.COMMENTS:
      newState.comments = value.map(item => {
        const countStr = changeNumShow(item.count);
        item.countStr = countStr;
        return item
      })
      break;
    case types.SUB_COMPARELIST:
      LOCAL_STORAGE.setItem('compareList', JSON.stringify(value), new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
      newState.compareList = JSON.parse(LOCAL_STORAGE.getItem('compareList'));
      break;
    case types.ADD_COMPARELIST:
      const _compareList = newState.compareList;
      if (_compareList.length < 4) {
        const compareList = [..._compareList, value];
        LOCAL_STORAGE.setItem('compareList', JSON.stringify(compareList), new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
        newState.compareList = JSON.parse(LOCAL_STORAGE.getItem('compareList'));
      }
      break;
    case types.MODAL_VISIBLITY:
      LOCAL_STORAGE.setItem('comapreModalShow', JSON.stringify(value), new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
      newState.modalShow = JSON.parse(LOCAL_STORAGE.getItem('comapreModalShow'));
      break;
    case types.TIP_STATUS:
      const { tipShow, tips } = value;
      newState.tipShow = tipShow;
      newState.tips = tips;
      break;
    case types.PRODUCTS_FOLLOW_STATUS:
      newState.productsFollowStatus = value;
      newState.isLogin = true;
      break;
    case types.FOLLOW_PRODUCT:
      const { skuId, status } = value;
      newState.productsFollowStatus = { ...newState.productsFollowStatus, [skuId]: status }
      break;
    case types.LOGIN_STATUS:
      newState.isLogin = value;
      break;
    case types.SEARCH_LOADING:
      newState.searchLoading = value;
      break

    default:
      break;
  }

  return newState;
}

export default searchReducer;