import * as types from '../actionTypes';

import { FLOOR_TYPE_NAVIGATION, FLOOR_TYPE_FOOTER, FLOOR_TYPE_HEAD, FLOOR_TYPE_MAIN } from '../../api/types';

const defaultState = {
  shortCutData: {},
  headData: {},
  footerData: {},
  navData: {},
  nickName: null,
}


const commonReducer = (state = defaultState, { type, value }) => {
  let newState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case types.COMMON_DATA:
      const { nickName = '', data = [] } = value;
      newState.nickName = nickName;
      newState.isLogin = !nickName ? false : true;
      data.forEach(item => {
        switch (item.floorType) {
          case FLOOR_TYPE_NAVIGATION:
            newState.shortCutData = item.navFloor || {};
            break;
          case FLOOR_TYPE_FOOTER:
            newState.footerData = item.footerFloor || {};
            break;
          case FLOOR_TYPE_HEAD:
            newState.headData = item.headerFloor || {};
            break;
          case FLOOR_TYPE_MAIN:
            newState.navData = item || {};
            break;
          default:
            break;
        }
      })
      break;
    default:
      break;
  }

  return newState;
}

export default commonReducer;