import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import applicationReducer from './application';
import searchReducer from './search';
import commonReducer from './commonData';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const reducer = combineReducers({
    applicationReducer,
    searchReducer,
    commonReducer
})

// 使用thunk让action支持异步操作  
export default createStore(reducer, composeEnhancers(applyMiddleware(thunk)));