import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Spin } from 'antd'
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import './App.css';

// const JDWIndex = lazy(() => import('./pages/home'))
const JDWSearch = lazy(() => import('./pages/search'))
const JDWNoPermission = lazy(()=> import('./pages/noPermission'))
const JDWNotFound = lazy(() => import('./pages/notFound'))
const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: '#e2231a' }} spin />;

class App extends React.Component {
  render() {

    // const { spinning } = this.props;

    return (
      <Router>
        <Suspense fallback={Loading()}>
          {/* <Spin spinning={spinning}> */}
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/Search" />} key={'index'} />
              <Route path={['/Search', '/search']} component={JDWSearch} />
              <Route path="/noPermission" exact component={JDWNoPermission} />
              <Route path="/404" component={JDWNotFound}></Route>
              <Redirect from="*" to="/404" exact></Redirect>
            </Switch>
          {/* </Spin> */}
        </Suspense>
      </Router>
    )
  }
}

function Loading() {
  return (
    <div className="jdw-loading">
      <Spin indicator={loadingIcon}/>
    </div>
  )
}

function mapStateToProps(state) {

  return {
    spinning: state.applicationReducer.spinning,
    userInfo: state.applicationReducer.userInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);