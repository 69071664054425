import * as types from '../actionTypes';
import * as floorTypes from '../../api/types';

const applicationState = {
    applicationData: [],
    bannerData: {},
    navData: {},
    headData: {},
    footerData: {},
    menuHoverIndex: '',
    nickName: null,
    spinning: false,
    showMenu: true
}

const applicationReducer = function (state = applicationState, action = {}) {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case types.APPLICATION_MENU_DATA:
            let nickName = action.value?.nickName || ''
            newState.nickName = nickName.length > 0 ? nickName : null
            newState.applicationData = action.value.data
            let newValue = action.value?.data || []
            newValue.forEach(item => {
                if (item.floorType === floorTypes.FLOOR_TYPE_BANNER) {
                    newState.bannerData = item.floorContent || {}
                } else if (item.floorType === floorTypes.FLOOR_TYPE_NAVIGATION){
                    newState.navData = item.navFloor || {}
                } else if (item.floorType === floorTypes.FLOOR_TYPE_FOOTER){
                    newState.footerData = item.footerFloor || {}
                } else if (item.floorType === floorTypes.FLOOR_TYPE_HEAD) {
                    newState.headData = item.headerFloor || {}
                }
            })
            break
        case types.APPLICATION_USERINFO_DATA:
            newState.nickName = action.value
            break
        case types.MENU_HOVER_INDEX:
            newState.menuHoverIndex = action.value
            break
        case types.SHOW_MENU:
            newState.showMenu = action.value;
            break;
        default:
            break;
    }
    // console.log(newState, 'applicationReducer')
    return newState;
}

export default applicationReducer;