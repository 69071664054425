export const LOCAL_STORAGE = {
  // 存储过期时间映射
  setExpireMap: (key, expire) => {
    const expireMap = localStorage.getItem('EXPIRE_MAP') || "{}";
    localStorage.setItem(
      'EXPIRE_MAP',
      JSON.stringify({
        ...JSON.parse(expireMap),
        [key]: expire
      }))
  },

  setItem: (key, value, expire) => {
    LOCAL_STORAGE.setExpireMap(key, expire)
    localStorage.setItem(key, value)
  },

  getItem: (key) => {
    // 在取值之前先判断是否过期
    const expireMap = JSON.parse(
      localStorage.getItem('EXPIRE_MAP') || "{}"
    )
    // console.log(key, expireMap[key] , Date.now())
    if (expireMap[key] && expireMap[key] > Date.now()) {
      return localStorage.getItem(key)
    } else {
      localStorage.removeItem(key)
      return null
    }
  }
}
