import queryString from 'query-string';


export const getQuery = (queryName = 'appId', history = window) => {
    const query = queryString.parse(history.location.search);
    const queryVal = query[queryName] ? query[queryName] : '';
    return queryVal;
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length)
        }
    }
    return null
}

export const formatPrice = (price) => {
    price = price || "";
    var arr = price.split(".");
    if (arr.length == 2) {
        if (arr[1] == "00") {
            return arr[0];
        } else if (arr[1] == "0000") {
            return arr[0];
        } else if (arr[1].indexOf("0") == arr[1].length) {
            return price.substring(0, price.length);
        } else {
            return price;
        }
    } else {
        return price;
    }
}

export const showProductsListStyle = (products) => {
    let stylev1 = 0, stylev2 = 0, stylev3 = 0, stylev4 = 0;
    let style;
    for (let product of products) {
        if (product.suiteItems.length >= 1 && product.colorItems.length === 0) {
            stylev1 = 1
        } else if (product.suiteItems.length >= 1 && product.colorItems.length >= 1) {
            stylev2 = 1
        } else if (product.suiteItems.length === 0 && product.colorItems.length >= 1) {
            stylev3 = 1
        } else if (product.suiteItems.length === 0 && product.colorItems.length === 0) {
            stylev4 = 1
        }
    }
    if (stylev1 === 1 || stylev2 === 1) {
        style = "v1" // 只要有套装，都按照无颜色分类处理
    } else if (stylev3 === 1) {
        style = "v3"
    } else if (stylev4 === 1) {
        style = "v4"
    }
    return style;
}

export const handleExtCatesData = (arr) => {
    const res = [];
    const func = (opt, id) => {
        if (!opt.extAttrs) {
            opt.forEach((item) => {
                item.id = `${id}_${item.id}`;
                res.push(item)
            })
            return;
        };
        func(opt.extAttrs, opt.id)
    }

    arr.map(item => func(item));
    return res;
}

export const getProductIds = (item, res = []) => {
    if (!item.length) return res;

    item.forEach(i => {
        i.skuId && res.push(i.skuId);
        if (i.colorItems?.length) {
            const text = getProductIds(i.colorItems, res);
            res.concat(text)
        };
    })

    return res;
}

export const getFirstOfProductsIds = (item) => {
    const res = [];
    item.map(item => res.push(item.skuId));
    return res;
}

export const urlParamsOrder = {
    keyword: 1,
    ev: 2
}

export const stringifySearch = (obj) => {
    const ev = [];
    if (obj.brands) {
        obj.brands.length && ev.push(`exbrand_${obj.brands.join('||')}`)
        delete obj.brands
    }
    if (obj.extAttrs) {
        obj.extAttrs.length && ev.push(obj.extAttrs.join('@'))
        delete obj.extAttrs
    }
    if (obj.pL || obj.pM) {
        ev.push(`exprice_M${!obj.pM ? '0' : obj.pM}${!obj.pL ? '' : `L${obj.pL}`}`)
        delete obj.pL;
        delete obj.pM;
    }

    // ev.length > 0 && ev.push('');
    if (!obj.ev) {
        ev.length > 0 && ev.push('');
        obj.ev = ev;
    } else {
        obj.tid && delete obj.tid;
        // obj.ev = `${obj.ev}${obj.ev[obj.ev.length - 1] === '@' ? '' : '@'}${ev.join('@')}`;
        obj.ev = `${obj.ev}${ev.length === 0 ? '' : '@'}${ev.join('@')}`;
    }


    obj.channelType && delete obj.channelType;

    return queryString.stringify(obj, {
        strict: false,
        arrayFormat: 'separator', arrayFormatSeparator: '@', sort: (a, b) => {
            if ((!urlParamsOrder[a] && urlParamsOrder[b])) {
                return 1
            }
            // else if(order[a] && !order[b]){
            //   return -1
            // }
            else if (urlParamsOrder[a] && urlParamsOrder[b]) {
                return urlParamsOrder[a] - urlParamsOrder[b]
            } else {
                return -1
            }
        }
    })
}

export const initUrlParams = (urlParams, operatePage = false) => {
    !operatePage && (urlParams.page = 1)
    !urlParams.stock && (urlParams.stock = 1)
}

export const changeNumShow = (numShow) => {
    if (numShow >= 10000) {
        if (numShow >= 100000) {
            numShow = parseInt(numShow / 10000) + '万+'
        } else {
            numShow = parseInt(numShow / 1000) / 10 + '万+'
        }
    } else if (numShow >= 100 && numShow < 10000) {
        numShow = parseInt(numShow / 100) + '00+'
    } else if (numShow >= 10 && numShow < 100) {
        numShow = parseInt(numShow / 10) + '0+'
    } else if (numShow > 0) {
        numShow = numShow
    } else {
        numShow = '0'
    }
    return numShow
}

export const isType = (val, type) => {
    return Object.prototype.toString.call(val) === `[object ${type}]`
}