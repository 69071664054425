
// common

export const COMMON_SPINNING_STATUS = "commonSpinningStatus";

// menu data
export const APPLICATION_MENU_DATA = "applicationMenuData";
// user data
export const APPLICATION_USERINFO_DATA = "applicationUserInfoData";
// banner data
export const APPLICATION_BANNER_DATA = "applicationBannerData";
// menu hover
export const MENU_HOVER_INDEX = "menuHoverIndex";

export const SHOW_MENU = 'showMenu'


// ------------- 公共部分 -----------------
export const COMMON_DATA = 'commonData';

// ------------- search页面 --------------------

export const SEARCH_PRODUCTS = "searchProducts";
export const MULFILTER = "mulFilter";
export const MULBTNIDX = "mulBtnIdx";
export const MOREBTNIDXS = 'moreBtnIdxs';
export const URL_PARAMS = 'urlParams';

export const RECOMMENDS = "recommends";
export const ADVERTISEMENTS = 'advertisement';
export const PRODUCTSPRICE = 'productsPrice'
export const COMMENTS = 'comments'; 
export const SHOPCHAT = 'shopchat';
export const ADD_COMPARELIST = 'addCompareList';
export const SUB_COMPARELIST = 'subCompareList';
export const MODAL_VISIBLITY = 'modalVisiblity';
export const TIP_STATUS = 'tipStatus';
export const CHANGE_CRUMB = 'changeCrumb';
export const PRODUCTS_FOLLOW_STATUS = 'productsFollowStatus';
export const FOLLOW_PRODUCT = 'followProduct';
export const LOGIN_STATUS = 'loginStatus';
export const SEARCH_LOADING = 'searchLoading'